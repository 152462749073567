var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.declareDetail.declareAuditList &&
        _vm.declareDetail.declareAuditList.length > 0
    )?_c('div',{staticClass:"audit_remark"},_vm._l((_vm.declareDetail.declareAuditList),function(item){return _c('p',{key:item.id},[_c('i',{class:item.status == 1
                    ? 'toast_title'
                    : item.status == 0
                    ? 'toast_title delete'
                    : 'toast_title pay'},[_vm._v(_vm._s(item.status == 1 ? '通过' : item.status == 0 ? '驳回' : '支付'))]),_c('span',[_vm._v(_vm._s(item.status == 2 ? '支付确认' : '审批备注')+"-"+_vm._s(item.adminName || '- -')+" "),_c('br'),_c('i',[_vm._v(_vm._s(item.createTime || '- -'))])]),_c('em',[_vm._v(_vm._s(item.remark || '- -'))])])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }