<template>
    <div
        class="detailed"
        v-if="
            declareDetail.declareDetailList &&
            declareDetail.declareDetailList.length > 0
        "
    >
        <div
            v-for="(item, index) in declareDetail.declareDetailList"
            :key="item.id"
        >
            <p class="detail_title" style="color: #ff8a00">
                明细{{ index + 1 }}
            </p>
            <div>
                <p>
                    <span>类型</span>
                    <em style="display: block">
                        {{ $cost.declareDetailType(item.type) }}
                    </em>
                </p>
            </div>
            <div>
                <p>
                    <span>金额</span>
                    <em style="display: block">
                        {{ item.declareAmount + '元' }}
                        <br />
                        {{
                            '大写：（' +
                            $tableDataHandle.ToString(item.declareAmount) +
                            '）'
                        }}
                    </em>
                </p>
            </div>
            <div>
                <p>
                    <span>备注信息</span>
                    <em>{{ item.remark || '- -' }}</em>
                </p>
            </div>
            <div>
                <p>
                    <span>附件</span>
                    <em
                        v-for="itm in item.declareDetailAttachmentBOList"
                        :key="itm.attachmentUrl"
                        v-if="itm.attachmentUrl"
                        class="img_box"
                        @click="
                            openFile(
                                itm.attachmentUrl,
                                itm.attachmentName.split('.')[1]
                            )
                        "
                    >
                        <i
                            v-if="
                                itm.attachmentName.split('.')[1] == 'pdf' ||
                                itm.attachmentName.split('.')[1] == 'PDF'
                            "
                        >
                            <img
                                src="../../../../../../img/pdfUrl.png"
                                alt=""
                            />
                        </i>
                        <i
                            v-if="
                                itm.attachmentName.split('.')[1] == 'doc' ||
                                itm.attachmentName.split('.')[1] == 'docx'
                            "
                        >
                            <img src="../../../../../../img/word.png" alt="" />
                        </i>
                        <i
                            v-if="
                                itm.attachmentName.split('.')[1] == 'jpg' ||
                                itm.attachmentName.split('.')[1] == 'jpeg' ||
                                itm.attachmentName.split('.')[1] == 'png' ||
                                itm.attachmentName.split('.')[1] == 'PNG'
                            "
                        >
                            <img :src="itm.attachmentUrl" alt="" />
                        </i>
                    </em>
                    <em v-else>- -</em>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: ['declareDetail'],
    data() {
        return {
            allMoney: 0,
            actualTotalAmount: 0,
        };
    },
    watch: {
        // declareDetail: {
        //   deep: true,
        //   handler(val) {
        //     if (val.id) {
        //       this.allMoney = 0;
        //       val.declareDetailList.forEach((item) => {
        //         this.allMoney += item.declareAmount;
        //       });
        //     }
        //   },
        // },
    },
    components: {},
    created() {},
    methods: {
        getData() {
            this.allMoney = 0;
        },
        openFile(url, type) {
            if (
                type == 'png' ||
                type == 'jpg' ||
                type == 'jpeg' ||
                type == 'PNG' ||
                type == 'JPG' ||
                type == 'JPEG'
            ) {
                window.open(url);
                return;
            }
            window.open(
                'https://view.officeapps.live.com/op/view.aspx?src=' + url
            );
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.detailed {
    > div {
        div {
            margin-bottom: 16px;
            display: flex;
            font-size: 14px;
            p {
                flex: 1;
                color: #333333;
                display: flex;
                span {
                    color: #666666;
                    display: block;
                    width: 56px !important;
                    white-space: nowrap;
                    margin-right: 12px;
                }
                em {
                    flex: 1;
                    font-style: normal;
                    font-weight: 600;
                    img {
                        margin-right: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .detail_title {
        margin-bottom: 16px;
    }

    .img_box {
        img {
            width: 100px;
            height: 100px;
        }
    }
}
</style>
