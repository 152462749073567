<template>
    <div class="detail">
        <div class="detail_box">
            <div style="background: #fff; padding: 14px 34px 20px 20px">
                <p class="detail_title" style="color: #2370eb">
                    {{ declareDetail.adminName }} 提交的申报申请
                </p>
                <DeclareInfo
                    v-if="declareDetail.id"
                    :declareDetail="declareDetail"
                    ref="declareInfo"
                    @update="update"
                ></DeclareInfo>

                <Detailed
                    v-if="declareDetail.id"
                    :declareDetail="declareDetail"
                    ref="detailed"
                ></Detailed>
                <p
                    class="detail_title"
                    style="color: #50cc1d"
                    v-if="
                        declareDetail.declareAuditList && declareDetail.declareAuditList.length > 0
                    "
                >
                    审批备注
                </p>
                <AuditRemark v-if="declareDetail.id" :declareDetail="declareDetail"></AuditRemark>
            </div>
            <div style="min-height: 10px; background: #f0f0f0"></div>
            <div class="comment">
                <div v-for="item in commentData" style="padding-bottom: 20px">
                    <p style="padding-bottom: 6px; font-size: 14px">
                        <span style="color: #2370eb"
                            ><i
                                class="el-icon-user-solid"
                                style="color: #999999; margin-right: 8px"
                            ></i
                            >{{ item.adminName }}</span
                        >
                        <span style="color: #666666"> {{ item.createTime }}</span>
                    </p>
                    <p class="comment_text">
                        <span>{{ item.comment }}</span>
                        <el-button
                            v-if="item.adminId == adminId"
                            @click="onDelete(item)"
                            type="text"
                            icon="el-icon-delete"
                            style="color: #da5120"
                        ></el-button>
                    </p>
                </div>
                <div style="display: flex">
                    <el-input type="textarea" row="1" placeholder="请输入内容" v-model="comment">
                    </el-input>
                    <el-button
                        @click="onComment"
                        type="primary"
                        style="background: #2370eb; border: none; margin-left: 8px"
                        >发送评论</el-button
                    >
                </div>
            </div>
        </div>
        <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="padding: 7px 20px; width: 80px; height: 26px; border-radio: 26px"
                size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="
                    margin-right: 15px;
                    background: #2370eb;
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                @click="onSubmit"
                size="mini"
                >{{ subVal }}</el-button
            >
        </div>
    </div>
</template>

<script>
import DeclareInfo from './components/declareInfo.vue';
import Detailed from './components/detailed.vue';
import AuditRemark from './components/auditRemark.vue';
import { contracGet } from '@/api/contr/contr.js';
import { addComment, commentList, commentDelete } from '@/api/cost/declare.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            declareDetail: {},
            userType: sessionStorage.getItem('userType'),
            subVal: '',
            actualTotalAmount: 0,
            routePath: this.$route.path,
            btnP: {},
            comment: '',
            commentData: [],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    watch: {},
    components: {
        DeclareInfo,
        Detailed,
        AuditRemark,
    },
    methods: {
        getData(data, btnP = {}) {
            console.log(data);
            this.btnP = btnP;
            this.declareDetail = data;

            setTimeout(() => {
                this.$refs.detailed.getData();
                this.$refs.declareInfo.getData(btnP);
            }, 0);
            this.contracGet(data.contractId);
            this.actualTotalAmount = data.actualTotalAmount;
            if (this.routePath == '/declareAudit') {
                this.subVal = '审 批';
            } else {
                this.subVal = '确 定';
            }
            this.getCommentList(data.id);
        },
        getCommentList(id) {
            let data = {
                param: {
                    declareId: id,
                },
            };
            commentList(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.commentData = res.data.list;
                }
            });
        },
        update() {
            this.$emit('update', this.declareDetail.id);
        },
        contracGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            contracGet(data).then((res) => {
                if (res.code == 200) {
                    this.declareDetail.contract = res.data;
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            console.log(this.subVal);
            if (this.subVal == '确 定') {
                this.$emit('close');
            } else {
                this.$emit('onAudit', this.declareDetail);
            }
        },
        onComment() {
            let data = {
                param: {
                    declareId: this.declareDetail.id,
                    comment: this.comment,
                },
            };
            if (!this.comment) {
                return this.$message.error('请输入评论');
            }
            addComment(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.comment = '';
                    this.getCommentList(this.declareDetail.id);
                }
            });
        },
        onDelete(item) {
            let data = {
                param: {
                    id: item.id,
                },
            };
            commentDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getCommentList(this.declareDetail.id);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.detail {
    width: 100%;
    height: 100%;
    font-size: 14px;
    background: #fff;
    display: flex;
    flex-direction: column;
    .comment {
        background: #fff;
        margin-top: 10px;
        padding: 14px 34px 20px 20px;
    }
    .detail_title {
        margin-bottom: 16px;
    }
    .detail_box {
        flex: 1;
        display: flex;
        overflow: auto;
        flex-direction: column;
    }
    .bottom-but {
        bottom: 0;
        right: 0;
        width: 480px;
        height: 48px;
        background: #fff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}
.comment_text {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.all_money {
    width: 480px;
    padding-right: 34px;
    padding-left: 20px;
    box-sizing: border-box;
    position: fixed;
    right: 0;
    bottom: 49px;
    height: 48px;
    background-color: #f0f4ff;
    p {
        width: 100%;
        display: flex;
        height: 48px;
        align-items: center;
    }
    span {
        font-weight: bold;

        flex: 1;
        display: block;
        width: 56px !important;
        white-space: nowrap;
        margin-right: 12px;
    }
    em {
        font-weight: 600;
        font-style: normal;
    }
}
</style>
