var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"declare_info"},[_c('div',[_c('p',[_c('span',[_vm._v("所在部门")]),_c('em',[_c('el-tooltip',{attrs:{"placement":"top"}},[_vm._v("· "),_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.declareDetail.departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.returnName(_vm.declareDetail))+" ")])])],1)]),_c('p',[_c('span',[_vm._v("提交日期")]),_c('em',[_vm._v(_vm._s(_vm.$tableDataHandle.createTime(_vm.declareDetail)))])])]),_c('div',[_c('p',[_c('span',[_vm._v("客户")]),(_vm.declareDetail.customer)?_c('em',[_vm._v(_vm._s(_vm.declareDetail.customer.companyName || '- -'))]):_vm._e()])]),_c('div',[_c('p',[_c('span',[_vm._v("客户名称")]),_c('em',[_vm._v(_vm._s(_vm.declareDetail.customerName || '- -'))])])]),_c('div',[_c('p',[_c('span',[_vm._v("申报状态")]),_c('em',[_vm._v(_vm._s(_vm.$cost.costStatus(_vm.declareDetail.status)))])]),_c('p',[_c('span',[_vm._v("关联合同")]),(_vm.declareDetail.contract)?_c('em',[_vm._v(_vm._s(_vm.declareDetail.contract.contractNo))]):_c('em',[_vm._v("- -")])])]),(
            _vm.declareDetail.contract &&
            _vm.declareDetail.contract.contractTargetBOList &&
            _vm.declareDetail.contract.contractTargetBOList.length > 0
        )?_c('div',{staticClass:"htbd"},_vm._l((_vm.declareDetail.contract
                .contractTargetBOList),function(item,index){return _c('p',{key:item.id},[_c('span',[_vm._v(_vm._s(index == 0 ? '合同标的' : ''))]),_c('em',[_vm._v(_vm._s(item.productAttributeRelationBOList && item.productAttributeRelationBOList.length > 0 ? item.productAttributeRelationBOList[0].productName : '- -')+" : "+_vm._s(item.receivableAmount || 0)+"元")])])}),0):_c('div',[_vm._m(0)]),_c('div',[_c('p',[_c('span',[_vm._v("合同金额")]),(_vm.declareDetail.contract)?_c('em',[_vm._v(_vm._s(_vm.declareDetail.contract.contractAmount + '元'))]):_c('em',[_vm._v("- -")])])]),_c('div',{staticStyle:{"height":"28px"}},[(!_vm.update)?_c('p',[_c('span',[_vm._v("支付方式")]),(_vm.declareDetail.payWay)?_c('em',[_vm._v(_vm._s(_vm.declareDetail.payWay == 1 ? '垫付' : '直付')+" "),(
                        _vm.routePath == '/declareRecord' &&
                        _vm.btnP.payUpdate &&
                        _vm.declareDetail.status == 4
                    )?_c('i',{staticClass:"el-icon-edit-outline",on:{"click":_vm.toUpdate}}):_vm._e()]):_c('em',[_vm._v("- - "),(
                        _vm.routePath == '/declareRecord' &&
                        _vm.btnP.payUpdate &&
                        _vm.declareDetail.status == 4
                    )?_c('i',{staticClass:"el-icon-edit-outline",on:{"click":_vm.toUpdate}}):_vm._e()])]):_vm._e(),(_vm.update)?_c('p',[_c('span',[_vm._v("支付方式")]),_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.form.payWay),callback:function ($$v) {_vm.$set(_vm.form, "payWay", $$v)},expression:"form.payWay"}},[_c('el-option',{attrs:{"label":"垫付","value":1}}),_c('el-option',{attrs:{"label":"直付","value":2}})],1),_c('el-button',{attrs:{"round":""},on:{"click":_vm.toUpdate}},[_vm._v("取 消")]),_c('el-button',{staticStyle:{"background":"#2370eb"},attrs:{"type":"primary","round":""},on:{"click":_vm.declareUpdate}},[_vm._v("确 认")])],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("合同标的")]),_c('em',[_vm._v("- -")])])}]

export { render, staticRenderFns }