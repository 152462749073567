import http from '@/utils/http.js'


// 申报列表
export function declareList(data) {
     return http({
          url: "/jasoboss/business/declare/list",
          method: "post",
          data
     })
}
// 新增申报
export function declareAdd(data) {
     return http({
          url: "/jasoboss/business/declare/add",
          method: "post",
          data
     })
}
// 修改申报
export function declareUpdate(data) {
     return http({
          url: "/jasoboss/business/declare/update",
          method: "post",
          data
     })
}
// 申报审核
export function declareAudit(data) {
     return http({
          url: "/jasoboss/business/declare/audit/add",
          method: "post",
          data
     })
}
// 获取单个申报
export function declareGet(data) {
     return http({
          url: "/jasoboss/business/declare/get",
          method: "post",
          data
     })
}
// 获取本月统计
export function getDeclareBrief(data) {
     return http({
          url: "/jasoboss/business/declare/brief",
          method: "post",
          data
     })
}
// 添加评论
export function addComment(data) {
     return http({
          url: "/jasoboss/business/declare/comment/add",
          method: "post",
          data
     })
}
// 评论列表
export function commentList(data) {
     return http({
          url: "/jasoboss/business/declare/comment/list",
          method: "post",
          data
     })
}
// 评论删除
export function commentDelete(data) {
     return http({
          url: "/jasoboss/business/declare/comment/delete",
          method: "post",
          data
     })
}
// 获取角色月销售额度
export function getAdminSaleAmount(data) {
     return http({
          url: "/jasoboss/admin/view",
          method: "post",
          data
     })
}

// 费用信息管理列表
export function businessInfoList(data) {
     return http({
          url: "/jasoboss/business/info/list",
          method: "post",
          data
     })
}
// 费用信息管理新增
export function businessInfoAdd(data) {
     return http({
          url: "/jasoboss/business/info/add",
          method: "post",
          data
     })
}
// 费用信息管理编辑
export function businessInfoUpdate(data) {
     return http({
          url: "/jasoboss/business/info/update",
          method: "post",
          data
     })
}
// 费用信息管理删除
export function businessInfoDelete(data) {
     return http({
          url: "/jasoboss/business/info/delete",
          method: "post",
          data
     })
}
// 获取费用信息管理
export function businessInfoGet(data) {
     return http({
          url: "/jasoboss/business/info/get",
          method: "post",
          data
     })
}