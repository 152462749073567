<template>
    <div class="declare_info">
        <div>
            <p>
                <span>所在部门</span>
                <em>
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in declareDetail.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(declareDetail) }}
                        </em>
                    </el-tooltip>
                </em>
            </p>
            <p>
                <span>提交日期</span
                ><em>{{ $tableDataHandle.createTime(declareDetail) }}</em>
            </p>
        </div>
        <div>
            <p>
                <span>客户</span
                ><em v-if="declareDetail.customer">{{
                    declareDetail.customer.companyName || '- -'
                }}</em>
            </p>
        </div>
        <div>
            <p>
                <span>客户名称</span
                ><em>{{ declareDetail.customerName || '- -' }}</em>
            </p>
        </div>
        <div>
            <p>
                <span>申报状态</span
                ><em>{{ $cost.costStatus(declareDetail.status) }}</em>
            </p>
            <p>
                <span>关联合同</span
                ><em v-if="declareDetail.contract">{{
                    declareDetail.contract.contractNo
                }}</em>
                <em v-else>- -</em>
            </p>
        </div>
        <div
            class="htbd"
            v-if="
                declareDetail.contract &&
                declareDetail.contract.contractTargetBOList &&
                declareDetail.contract.contractTargetBOList.length > 0
            "
        >
            <p
                v-for="(item, index) in declareDetail.contract
                    .contractTargetBOList"
                :key="item.id"
            >
                <span>{{ index == 0 ? '合同标的' : '' }}</span
                ><em
                    >{{
                        item.productAttributeRelationBOList &&
                        item.productAttributeRelationBOList.length > 0
                            ? item.productAttributeRelationBOList[0].productName
                            : '- -'
                    }}
                    : {{ item.receivableAmount || 0 }}元</em
                >
            </p>
        </div>
        <div v-else>
            <p><span>合同标的</span><em>- -</em></p>
        </div>
        <div>
            <p>
                <span>合同金额</span
                ><em v-if="declareDetail.contract">{{
                    declareDetail.contract.contractAmount + '元'
                }}</em>
                <em v-else>- -</em>
            </p>
        </div>
        <div style="height: 28px">
            <p v-if="!update">
                <span>支付方式</span
                ><em v-if="declareDetail.payWay"
                    >{{ declareDetail.payWay == 1 ? '垫付' : '直付' }}
                    <i
                        class="el-icon-edit-outline"
                        v-if="
                            routePath == '/declareRecord' &&
                            btnP.payUpdate &&
                            declareDetail.status == 4
                        "
                        @click="toUpdate"
                    ></i>
                </em>
                <em v-else
                    >- -
                    <i
                        class="el-icon-edit-outline"
                        v-if="
                            routePath == '/declareRecord' &&
                            btnP.payUpdate &&
                            declareDetail.status == 4
                        "
                        @click="toUpdate"
                    ></i>
                </em>
            </p>
            <p v-if="update">
                <span>支付方式</span>
                <el-select
                    v-model="form.payWay"
                    placeholder="请选择"
                    size="small"
                >
                    <el-option label="垫付" :value="1"> </el-option>
                    <el-option label="直付" :value="2"> </el-option>
                </el-select>
                <el-button round @click="toUpdate">取 消</el-button>
                <el-button
                    type="primary"
                    @click="declareUpdate"
                    style="background: #2370eb"
                    round
                    >确 认</el-button
                >
            </p>
        </div>
    </div>
</template>

<script>
import { declareUpdate, declareGet } from '@/api/cost/declare.js';
import { contracGet } from '@/api/contr/contr.js';
export default {
    name: '',
    props: ['declareDetail'],
    data() {
        return {
            btnP: {},
            update: false,
            form: {
                payWay: '',
            },
            routePath: this.$route.path,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(btnP) {
            this.update = false;
            this.btnP = btnP;
        },
        toUpdate() {
            this.update = !this.update;
            if (this.update) {
                this.form.payWay =
                    JSON.parse(JSON.stringify(this.declareDetail.payWay)) || '';
            }
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },

        declareGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return declareGet(data);
        },
        async declareUpdate() {
            let res = await this.declareGet(this.declareDetail.id);
            if (res.code == 200) {
                if (res.data.status == 4) {
                    let data = {
                        param: {
                            id: this.declareDetail.id,
                            payWay: this.form.payWay,
                            status: this.declareDetail.status,
                        },
                    };
                    declareUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.declareDetail.payWay = this.form.payWay;
                            this.update = false;
                        }
                    });
                } else {
                    this.update = false;
                    return this.$message.error(
                        '关联申报已更新，无法变更支付方式'
                    );
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare_info {
    div {
        margin-bottom: 16px;
        display: flex;
        font-size: 14px;

        p {
            flex: 1;
            color: #333333;
            display: flex;
            span {
                color: #666666;
                display: block;
                width: 56px;
                white-space: nowrap;
                margin-right: 12px;
            }
            em {
                font-weight: 600;
                font-style: normal;
                flex: 1;
                i {
                    font-size: 16px;
                    color: #2370eb;
                    cursor: pointer;
                    vertical-align: -1px;
                    margin-left: 10px;
                }
            }
            .el-select {
                margin-top: -5px;
                margin-bottom: 0;
            }
            button {
                height: 25px;
                line-height: 0px;
                padding: 5px 12px;
                margin-left: 5px;
            }

            button + button {
                margin-left: 5px;
            }
        }
    }
    .htbd {
        display: table;
        p + p {
            margin-top: 5px;
        }
    }
}
</style>
